@import "alembic";
table {
        border: 1px solid #aaa;
}
th {
        padding: 10px;
        font-weight: bold;
        border: 1px solid #aaa;
}
td {
        padding: 10px;
        border: 1px solid #aaa;
}
